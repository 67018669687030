<style lang="less" scoped>
html,
body {
  height: 100%;
}
.trail {
  position: relative;

  .absolute-top {
    position: absolute;
    top: 8px;
    z-index: 1000;
    background: #fff;
    padding: 5px 0;
    text-align: center;
    margin-left: -450px;
    box-sizing: border-box;
    box-shadow: 0px 0px 8px rgba(51, 51, 51, 0.24);
    border-radius: 8px;

    &:after {
      content: "";
      display: block;
      height: 0;
      clear: both;
    }

    .input-group-inline {
      padding-bottom: 0;
      margin-right: 16px;
    }

    .r {
      float: right;
    }
  }

  .map-navigator-control {
    position: absolute;
    background: rgba(51, 51, 51, 0.8);
    padding: 5px 12px;
    box-sizing: border-box;
    height: 36px;
    margin: 0 0 0 -310px;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    z-index: 99;
    width: 1020px;
    left: 38%;

    .speed-box {
      padding: 7px 12px;
      cursor: pointer;
    }
    .speed-radio {
      width: 24px;
      line-height: 34px;
      color: white;
      font-size: 12px;
      margin: 0 12px;
      cursor: pointer;
    }
    .select-radio {
      border-bottom: 2px solid #ff7e33;
    }
    .not-select-radio {
      border-bottom: none;
    }
  }

  .marker-tip {
    position: absolute;
    right: 8px;
    bottom: 300px;
  }

  .absolute-bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1000;
    background: #fff;
    width: 100%;
    max-height: 240px;
    overflow: hidden;
  }

  .map-box {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
}

.i-icon {
  display: inline-block;

  &.i-icon-base {
    background: url("https://oss.kuaihuoyun.com/xhk-bops/base.png");
    background-size: 12px 15px;
    width: 12px;
    height: 15px;
  }

  &.i-icon-gps {
    background: url("https://oss.kuaihuoyun.com/xhk-bops/gps.png");
    background-size: 12px 15px;
    width: 12px;
    height: 15px;
  }

  &.i-icon-wifi {
    background: url("https://oss.kuaihuoyun.com/xhk-bops/wifi.png");
    background-size: 12px 15px;
    width: 12px;
    height: 15px;
  }

  &.i-icon-play {
    background: url("../../img/asset/img/icon-play.png");
    background-size: 12px 16px;
    width: 12px;
    height: 16px;
  }

  &.i-icon-stop {
    background: url("../../img/asset/img/icon-pause.png");
    background-size: 12px 16px;
    width: 12px;
    height: 16px;
  }
}

.text-icon {
  display: inline-block;
  margin-right: 10px;
  line-height: 14px;
  vertical-align: top;

  &:last-child {
    margin-right: 0;
  }
}
</style>

<template>
  <div
    id="trail"
    class="page-content trail"
    :style="
      `height: ${
        page === 'billTrack'
          ? 'calc(100vh - 48px)'
          : page === 'troubleShoot'
          ? 'calc(100vh - 48px)'
          : '100%'
      } ;`
    "
  >
    <Spin size="large" fix v-show="loading">加载中...</Spin>
    <div
      v-if="page !== 'billTrack'"
      class="absolute-top"
      :style="
        `${
          page === 'troubleShoot'
            ? 'width: 1150px; left: 40%'
            : 'width: 900px; left: 50%'
        }`
      "
    >
      <div v-if="page === 'troubleShoot'" class="input-group-inline">
        运单号
        <Input
          class="control-width"
          v-model="shippingNoteNumber"
          placeholder="请输入运单号"
        />
      </div>
      <div class="input-group-inline">
        设备号
        <Input
          class="control-width"
          v-model="device"
          placeholder="请输入设备号"
        />
      </div>
      <div class="input-group-inline">
        时间
        <DatePicker
          type="datetimerange"
          split-panels
          placeholder="设备运行时间"
          :value="timeRange"
          @on-change="updateTimeRange"
          format="yyyy-MM-dd HH:mm:ss"
          :editable="false"
          style="width: 360px;"
        >
        </DatePicker>
      </div>
      <div class="input-group-inline" v-if="!loading">
        <Button type="primary" @click="getTrailByDevice">确定</Button>
        <Button
          type="text"
          size="default"
          style="color: #2d8cf0;"
          :loading="detailLoading"
          @click="toggleTrailDetail"
        >
          {{ showDetailTable ? "关闭明细" : "轨迹明细" }}
        </Button>
      </div>
    </div>
    <div id="container" class="map-box"></div>
    <div class="absolute-bottom">
      <div v-show="showDetailTable">
        <Table
          id="table"
          highlight-row
          stripe
          border
          ref="table"
          size="small"
          :height="200"
          :columns="columns"
          :loading="loading"
          :data="tableData"
        ></Table>
        <div style="padding: 5px;overflow: hidden;">
          <Button size="small" @click="hideTable" style="margin-right: 5px;"
            >关闭列表</Button
          >
          <Button size="small" @click="exportExcel">导出列表</Button>
          <div style="float: right;">
            <Page
              :total="totalItems"
              :current="currentPage"
              show-total
              :page-size="pageSize"
              @on-change="changePage"
            ></Page>
          </div>
        </div>
      </div>
    </div>
    <div
      class="map-navigator-control flex flex-middle"
      :style="page === 'billTrack' ? 'top: 5px;' : 'top: 65px;'"
    >
      <div class="flex">
        <div
          v-for="(item, idx) in speedRadioList"
          :key="idx"
          class="speed-radio"
          :class="speedLevel === item ? 'select-radio' : 'not-select-radio'"
          @click="selectRadio(item)"
        >
          {{ item }}
        </div>
      </div>
      <div
        style="width: 320px; margin: 0 10px;position: relative;z-index: 1001;"
      >
        <Slider
          v-model="navigatorProgress"
          :tip-format="navigatorProgressTip"
          @on-change="changeNavigatorProgress"
        ></Slider>
      </div>
      <div style="margin-right: 10px;">
        <Button
          size="small"
          type="text"
          disabled
          style="border:none;color:white;background: none;font-size:10px;"
        >
          {{ Math.round(playDistance / 1000) }}km/{{
            Math.round(totalDistance / 1000)
          }}km
        </Button>
      </div>
      <div class="speed-box">
        <Icon
          type="md-play"
          color="#fff"
          size="16"
          v-show="navigatorState === 'stop' || navigatorState === 'pause'"
          @click="playNavigator"
        />
        <Icon
          type="md-pause"
          color="#fff"
          size="16"
          v-show="navigatorState === 'play'"
          @click="stopNavigator"
        />
      </div>
      <div style="margin-left: 16px;color:white;">
        定位方式：
        <Select
          v-model="positionType"
          multiple
          style="width:200px"
          size="small"
          @on-change="positionTypeChange"
        >
          <Option
            v-for="item in positionList"
            :value="item.value"
            :key="item.value"
            >{{ item.label }}</Option
          >
        </Select>
      </div>
    </div>
    <marker-tip class="marker-tip"></marker-tip>
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
import mapMixins from "../../components/mixins/map.vue";
import { getAddressNode, getLocation } from "../../libs/deviceUtil";
import { ajax } from "../../libs/util";
import markerTip from "../map/markerTip.vue";

const tableColumn = [
  {
    type: "index",
    title: "序号",
    width: 80,
    align: "center",
  },
  {
    title: "定位时间",
    width: 160,
    align: "left",
    key: "locateTime",
    render: (h, params) => {
      let dateStr = moment(params.row.locateTime * 1000).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      return h("span", dateStr);
    },
  },
  {
    title: "定位类型",
    width: 120,
    align: "center",
    key: "locateTypeName",
  },
  {
    title: "经度/纬度",
    width: 180,
    align: "left",
    render: (h, params) => {
      return h("span", `${params.row.lng}/${params.row.lat}`);
    },
  },
  // {
  //   title: 'GSM信号',
  //   width: 120,
  //   align: 'center',
  //   key: 'gsm',
  // },
  {
    title: "电量",
    width: 80,
    align: "center",
    render: (h, params) => {
      return h("span", `${params.row.powerRate || 0}%`);
    },
  },
  {
    title: "速度(km/h)",
    width: 120,
    align: "center",
    key: "speed",
  },
  {
    title: "方向",
    width: 120,
    align: "center",
    key: "directionName",
  },
  {
    title: "定位模式",
    width: 100,
    align: "center",
    key: "modeName",
  },
  {
    title: "定位周期",
    width: 100,
    align: "center",
    key: "reportedRate",
    render: (h, params) => {
      const { mode, reportedRate } = params.row;
      let dw = "";
      switch (mode) {
        case 0:
          dw = "分钟/次";
          break;
        case 1:
          dw = "秒/次";
          break;
        default:
          break;
      }

      return h("span", reportedRate ? reportedRate + dw : "");
    },
  },
  // {
  //   title: '海拔',
  //   minWidth: 100,
  //   key: 'altitude',
  //   render: (h, params) => {
  //     return h('span', params.row.altitude ? `${params.row.altitude}米` : '')
  //   },
  // },
  {
    title: "位置",
    minWidth: 300,
    align: "left",
    key: "address",
    render: (h, params) => {
      const { address } = params.row;
      if (address) {
        return h("span", address);
      } else {
        return h(
          "a",
          {
            props: {
              href: "javascript:;",
            },
            on: {
              click: () => {
                getLocation(params.row);
              },
            },
          },
          "查看地址"
        );
      }
    },
  },
];
const speedConfig = [
  { level: "慢速", time: 20 },
  { level: "正常", time: 15 },
  { level: "快速", time: 10 },
  { level: "极快", time: 5 },
];

let _cacheTableData = [];

export default {
  name: "trailBack",
  mixins: [mapMixins],
  components: {
    markerTip,
  },
  data() {
    let yesterday = moment(Date.now()).format("YYYY-MM-DD 00:00:00");
    let today = moment().format("YYYY-MM-DD 23:59:59");
    return {
      page: "",
      device: "",
      shippingNoteNumber: "",
      locateType: 0,
      timeRange: [new Date(yesterday), new Date(today)],
      trackList: [], // 筛选过后的
      totalTrackList: [],
      trackAbnormalList: [], //异常点
      //_tableData: [],
      tableData: [],
      currentPage: 1,
      pageSize: 50,
      totalItems: 0,
      map: null,
      iconMap: {},
      columns: tableColumn,
      loading: false,
      detailLoading: false,
      showDetail: false,
      showDetailTable: false,
      navigatorControl: null, //轨迹控制器
      navigatorMarker: null, //巡航器信息
      navigatorState: "stop",
      navigatorProgress: 0,
      playDistance: 0,
      speedLevel: "正常",
      ...this.$route.query,
      positionList: [
        { label: "GPS", value: 1 },
        { label: "基站", value: 2 },
        { label: "wifi", value: 6 },
      ],
      positionType: [],
      fenceInfoList: [],
      speedRadioList: ["慢速", "正常", "快速", "极快"],
    };
  },
  computed: {
    //轨迹总里程
    totalDistance() {
      const { trackList } = this;
      if (trackList.length > 0) {
        let pList = trackList
          .filter((item) => item.lng && item.lat)
          .map((item) => {
            return [item.lng, item.lat];
          });
        return Math.round(AMap.GeometryUtil.distanceOfLine(pList));
      }
      return 0;
    },
    //巡航器速度 根据播放快慢和总里程计算
    navigatorSpeed() {
      let { speedLevel, totalDistance } = this;
      let level = speedConfig.find((item) => {
        return item.level === speedLevel;
      });
      if (totalDistance && level && level.time) {
        return Math.round((totalDistance * 3.6) / level.time);
      } else {
        return 10000;
      }
    },
  },
  watch: {
    //切换播放速度
    speedLevel() {
      this.$nextTick(() => {
        const { navigatorSpeed, navigatorControl } = this;
        navigatorControl && navigatorControl.setSpeed(navigatorSpeed);
      });
    },
  },
  methods: {
    toggleTrailDetail() {
      //console.log('detailLoading',this.showDetail);
      if (!this.showDetail) {
        this.detailLoading = true;
      }
      setTimeout(() => {
        this.showDetail = !this.showDetail;
        this.showDetailTable = this.showDetail;
        this.map && this.map.clearMap();
        this.tableData = this.getTableData(this.currentPage);
        //显示轨迹点
        if (this.showDetail) {
          this.setMapMarker();
        } else {
          this.showExtremePoint(this.trackList);
          this.showNavigatorMarker();
        }
        this.setAbnormalMarker();
        setTimeout(() => {
          this.detailLoading = false;
        }, 200);
      }, 0);
    },
    getTableData(page = 1) {
      let { pageSize } = this;
      let start = pageSize * (page - 1);
      let end = start + pageSize;
      //console.log(start,end);
      return _cacheTableData.slice(start, end) || [];
    },
    changePage(page) {
      this.currentPage = page;
      this.tableData = this.getTableData(page);
      //console.log(this.tableData,this.getTableData(page));
    },
    refreshTable() {
      this.currentPage = 1;
      this.tableData = this.getTableData(1);
    },
    updateTimeRange(str, date) {
      // str[1]=str[1].replace('00:00:00','23:59:59')
      this.timeRange = str;
    },
    getTrailQueryData() {
      let { device, locateType } = this;
      if (!device) {
        this.$Message.error("请输入设备号！");
        return;
      }

      let startTime2 = moment(this.timeRange[0]).unix();
      let endTime2 = moment(this.timeRange[1]).unix();
      let startTime = moment(this.timeRange[0]).format("YYYY-MM-DD HH:mm:ss");
      let endTime = moment(this.timeRange[1]).format("YYYY-MM-DD HH:mm:ss");

      if (endTime2 - startTime2 > 180 * 24 * 60 * 60) {
        this.$Message.error("时间跨度大于180天，请缩小时间范围。");
        return;
      }
      return {
        device: device,
        locateType: locateType ? locateType : "",
        startTime: startTime2,
        endTime: endTime2,
      };
    },
    requestTrackList() {
      //console.log(this.timeRange);
      let queryData = this.getTrailQueryData();
      if (!queryData) return;
      //console.log(startTime,endTime);
      this.map && this.map.clearMap();
      this.pathSimplifierIns && this.pathSimplifierIns.setData();
      // this.loading = true;

      //定位模式是全部时显示设备的异常点
      ajax.post(
        "/device/get_track",
        {
          ...queryData,
        },
        (res) => {
          let trackList = res.data || [];
          this.setFenceCircle();
          if (trackList.length === 0) {
            this.$Message.error("该时间段暂无轨迹！");
            this.totalTrackList = [];
            this.trackList = [];
            _cacheTableData = [];
            this.totalItems = 0;
            this.tableData = [];
            return {};
          }
          this.totalTrackList = trackList;

          this.drawTrail();

          if (res.body && res.body.length > 0) {
            this.trackAbnormalList = res.body;
            this.setAbnormalMarker();
          }
          this.loading = false;
        },
        (error) => {
          this.$Message.error(
            error.message || error.msg || "服务器异常，请重试"
          );
          this.loading = false;
        }
      );
    },
    getTrailByDevice() {
      if (this.page === "troubleShoot") {
        if (!!this.shippingNoteNumber) {
          ajax.post(
            "/shipping/get_shipping_note",
            {
              shippingNoteNumber: this.shippingNoteNumber,
            },
            (res) => {
              if (res.data) {
                this.device = res.data.device;
                this.fenceInfoList = res.data.fenceInfoList;
                this.requestTrackList();
              } else {
                this.$Message.error("运单号不存在");
              }
            },
            (error) => {
              this.$Message.error(
                error.message || error.msg || "服务器异常，请重试"
              );
            }
          );
        } else {
          ajax.post(
            "/shipping/get_fence_rear",
            { device: this.device },
            (res) => {
              if (res && res.data && !!res.data.length) {
                this.fenceInfoList = res.data;
                this.requestTrackList();
              }
            },
            (err) => {
              this.$Message.error(
                err.message || err.msg || "服务器异常，请重试"
              );
            }
          );
        }
      } else {
        this.requestTrackList();
      }

      // ajax.post({
      //     data: {
      //         service: 'device/get_track',
      //         ...queryData
      //     },
      // })
      //     .then(res => {
      //         let trackList = res.body || [];
      //         if (trackList.length === 0) {
      //             this.$Message.error('该时间段暂无轨迹！');
      //             this.trackList = [];
      //             _cacheTableData = [];
      //             this.totalItems = 0;
      //             this.tableData = [];
      //             return {};
      //         }
      //         this.trackList = trackList
      //         //trackList = trackList.reverse(); //倒序
      //         _cacheTableData = [].concat(trackList);
      //         _cacheTableData.reverse(); //倒序
      //         this.totalItems = _cacheTableData.length;
      //         //console.log(_cacheTableData);
      //         //this.trackList = trackList;
      //         //console.log(this._tableData,this.trackList);
      //         //轨迹
      //         this.setPathData(trackList);
      //
      //         if (this.showDetail) {
      //             this.setMapMarker();
      //             this.refreshTable();
      //         }
      //         //this.setMapMarker();
      //         //查询异常点
      //         if (!queryData.locateType) {
      //             return this.$axios({
      //                 data: {
      //                     service: 'device/get_track_point',
      //                     ...queryData
      //                 },
      //             });
      //         } else {
      //             this.loading = false;
      //             return {};
      //         }
      //     })
      //     .then(res => {
      //         //console.log(res);
      //         if (res.body && res.body.length > 0) {
      //             this.trackAbnormalList = res.body;
      //             this.setAbnormalMarker();
      //         }
      //         this.loading = false;
      //     })
      //     .catch(err => {
      //         this.loading = false;
      //         this.$Message.error(err.message || '服务器异常，请重试');
      //     })
    },
    //轨迹
    setPathData(trackList = []) {
      let { pathSimplifierIns, navigatorSpeed } = this;
      if (!pathSimplifierIns) {
        setTimeout(() => {
          this.setPathData(trackList);
        }, 100);
        return;
      }
      //只使用有定位的点
      let pathData = trackList.filter((item) => item.lng && item.lat);
      if (pathData.length > 0) {
        pathSimplifierIns.setData([
          {
            path: pathData,
          },
        ]);
        this.showExtremePoint(trackList);
        this.showNavigatorMarker();
        //播放进度回原
        this.playDistance = 0;
        this.navigatorProgress = 0;
        this.navigatorState = "stop";
        //创建一个巡航器
        this.navigatorControl = pathSimplifierIns.createPathNavigator(0, {
          loop: false, //循环播放
          speed: navigatorSpeed,
        });
        let vm = this;
        window.clickViewAdd = function() {
          let { currentPoint, navigatorMarker } = vm;
          //console.log('currentPoint',currentPoint);
          getLocation(currentPoint, (err, point) => {
            let markerContent = vm.getNavigatorMarkerContent(point, true);
            navigatorMarker.setContent(markerContent);
            navigatorMarker.setOffset(new AMap.Pixel(-115, -120));
          });
        };
        this.navigatorControl.on("move", function() {
          vm.playDistance = Math.round(this.getMovedDistance());
          vm.navigatorProgress = Math.round(
            (vm.playDistance * 100) / vm.totalDistance
          );
          let currentPath = this.getCursor().idx;
          vm.currentPoint = pathData[currentPath];
          let { navigatorMarker } = vm;
          //console.log(point);
          let markerContent = vm.getNavigatorMarkerContent(
            vm.currentPoint,
            false
          );
          navigatorMarker.setContent(markerContent);
          navigatorMarker.setPosition(this.getPosition());
          navigatorMarker.setOffset(new AMap.Pixel(-80, -120));
          navigatorMarker.show();
          //播放完后重置
          if (vm.playDistance === vm.totalDistance) {
            vm.navigatorState = "stop";
          }
        });
      }
    },
    getNavigatorMarkerContent(point = {}, showAddressDetail) {
      let dateStr = moment(point.locateTime * 1000).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      let address = showAddressDetail
        ? `<span class="address-span">${point.address || ""}</span>`
        : `<a class="address-a" href="javascript:void(0)" onclick="clickViewAdd()">查看地址</a>`;
      return `<div class="info-car-box pop-shadow ${
        showAddressDetail ? "width" : ""
      }">
                    <p class="speed-p"><em></em><span class="speed-span">${point.speed ||
                      0}</span>km/h</p>
                    <p class="time-p"><em></em><span class="time-span">${dateStr}</span></p>
                    <!--<p class="address-p"><em></em>${address}</p>-->
                </div>`;
    },
    changeNavigatorProgress(value) {
      let { navigatorControl, totalDistance } = this;
      //console.log(navigatorControl,value);
      if (navigatorControl) {
        let dis = totalDistance * (value / 100);
        navigatorControl.start();
        navigatorControl.moveByDistance(dis);
        if (this.navigatorState !== "play") {
          navigatorControl.pause();
          this.navigatorState = "pause";
        }
      } else {
        this.$Message.error("没有轨迹");
        this.$nextTick(() => {
          this.navigatorProgress = 0;
        });
      }
    },
    stopNavigator() {
      let { navigatorControl } = this;
      if (navigatorControl) {
        navigatorControl.pause();
        this.navigatorState = "pause";
      } else {
        this.$Message.error("没有轨迹");
      }
    },
    playNavigator() {
      let { navigatorControl } = this;
      if (navigatorControl) {
        this.navigatorState === "pause"
          ? navigatorControl.resume()
          : navigatorControl.start();
        this.navigatorState = "play";
      } else {
        this.$Message.error("没有轨迹");
      }
    },
    navigatorProgressTip(val) {
      return `进度：${val}%`;
    },
    //异常点
    setAbnormalMarker() {
      let { abnormalIcon, map, trackAbnormalList, getInfoWindowOptions } = this;
      let markers = trackAbnormalList.map(function(item, i) {
        let icon = abnormalIcon[item.pointType];
        let marker = new AMap.Marker({
          position: [item.lng, item.lat],
          title: `停止点-${i + 1}`,
          icon: icon,
          offset: new AMap.Pixel(-12, -41),
        });
        AMapUI.loadUI(["overlay/SimpleInfoWindow"], function(SimpleInfoWindow) {
          let infoWindow = new SimpleInfoWindow(
            getInfoWindowOptions(item.pointType, item, i)
          );
          marker.on("click", function() {
            infoWindow.open(map, marker.getPosition());
          });
          AMap.event.addListener(marker, "mouseover", function() {
            infoWindow.open(map, marker.getPosition());
          });
          AMap.event.addListener(marker, "mouseout", function() {
            infoWindow.close();
          });
        });

        return marker;
      });
      map.add(markers);
    },
    getInfoWindowOptions(pointType = 1, data, index) {
      let gsmHtml = this.usableGSM(data.gsm)
        ? `<div class="blue">GSM：${data.gsm}</div>`
        : "";
      switch (pointType) {
        case 1:
          return {
            infoTitle: `<div class="flex flex-middle"><div>停止点-${index +
              1}</div>${gsmHtml}</div>`,
            infoBody: `<div class="info-window-ul">
                                <div><span>开始时间：</span>${data.startTimeDesc ||
                                  ""}</div>
                                <div><span>结束时间：</span>${data.endTimeDesc ||
                                  ""}</div>
                                <div style="color: red;"><span>停留时长：</span>${data.timeStepDesc ||
                                  ""}</div>
                                <div><span>地址：</span>${data.address ||
                                  ""}</div>
                            </div>`,
            offset: new AMap.Pixel(0, -30),
          };
        case 2:
          return {
            infoTitle: `<strong>开箱报警-${index + 1}</strong>`,
            infoBody: `<div class="info-window-ul">
                                <div><span>开箱时间：</span>${data.startTimeDesc ||
                                  ""}</div>
                                <div><span>开箱地址：</span>${data.address ||
                                  ""}</div>
                            </div>`,
            offset: new AMap.Pixel(0, -30),
          };
        default:
          return {};
      }
    },
    //起终点
    showExtremePoint(trackList = []) {
      if (trackList.length > 2) {
        let { extremePointIcon, map } = this;
        let startPoint = trackList[0];
        let endPoint = trackList.slice(-1)[0];
        if (startPoint.lat && startPoint.lng) {
          //console.log(startPoint,endPoint);
          let startPointMarker = new AMap.Marker({
            position: [startPoint.lng, startPoint.lat], //经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: `开始位置`,
            icon: extremePointIcon[1],
            offset: new AMap.Pixel(-20, -30),
          });
          map.add(startPointMarker);
        }
        if (endPoint.lat && endPoint.lng) {
          let endPointMarker = new AMap.Marker({
            position: [endPoint.lng, endPoint.lat], //经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            title: `结束位置`,
            icon: extremePointIcon[2],
            offset: new AMap.Pixel(-20, -30),
          });

          map.add(endPointMarker);
        }
      }
    },
    showNavigatorMarker() {
      let { map } = this;
      this.navigatorMarker = new AMap.Marker({
        offset: new AMap.Pixel(-80, -120),
        content: "",
        visible: false,
        zIndex: 200,
        map: map,
      });
    },
    setMapMarker() {
      let markerList = this.createMarkerList();
      markerList = markerList.filter((marker) => {
        return marker !== null;
      });
      //console.log(makerList);
      this.map.add(markerList);
      //this.map.setFitView();
    },
    usableGSM(value) {
      return !(value === undefined || value === null);
    },
    createMarker(track, i) {
      let map = this.map;
      if (track.lng && track.lat) {
        let trackAddressNode = getAddressNode(track);
        let icon =
          this.locateTypeIcon[track.locateType] || this.locateTypeIcon[1];
        let marker = new AMap.Marker({
          position: [track.lng, track.lat], //经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
          title: `定位点-${i + 1}`,
          icon: icon,
          offset: new AMap.Pixel(-12, -30),
        });
        let dateStr = moment(track.locateTime * 1000).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        let gsmHtml = this.usableGSM(track.gsm)
          ? `<div class="blue">GSM：${track.gsm}</div>`
          : "";

        const markerInfoWindow = new AMap.InfoWindow({
          content: `<div><div class="flex flex-middle" style="border-bottom: 1px solid #e2e2e2;padding-bottom: 8px;"><div class="flex-item">轨迹序号${i +
            1}</div>${gsmHtml}</div><div class="info-window-ul info-window-ul-self" style="padding-top: 8px;">
                                <div><span>定位类型：</span>${track.locateTypeName ||
                                  ""}</div>
                                <div><span>定位时间：</span>${dateStr}</div>
                                <div><span>电量：</span>${track.powerRate ||
                                  "0"}%${
            track.chargerState === 1
              ? '<div style="color: green;display: inline;">充电中</div>'
              : ""
          }</div>
                                <div><span>速度：</span>${track.speed ||
                                  0} 公里/小时</div>
                                ${
                                  track.temperature
                                    ? `<div><span>温度：</span>${track.temperature ||
                                        "0"} ℃</div>`
                                    : ""
                                }
                                ${
                                  track.humidity
                                    ? `<div><span>湿度：</span>${track.humidity ||
                                        "0"} %RH</div>`
                                    : ""
                                }
                                <div id="addr-${
                                  track.locateTime
                                }"><span>定位地点：</span>${trackAddressNode}</div>
                            </div></div>`,
          offset: new AMap.Pixel(0, -20),
        });
        let markerTimeout = null;
        marker.on("mouseover", (e) => {
          if (markerTimeout) {
            clearTimeout(markerTimeout);
            markerTimeout = null;
          }
          markerInfoWindow.open(map, marker.getPosition());
        });
        marker.on("mouseout", (e) => {
          if (!markerTimeout) {
            markerTimeout = setTimeout(() => {
              markerInfoWindow.close();
            }, 1000);
          }
        });

        return marker;
      } else {
        return null;
      }
    },
    createMarkerList() {
      let max = 500;
      let trackList = _cacheTableData;
      if (_cacheTableData.length > max) {
        trackList = _cacheTableData.slice(_cacheTableData.length - max);
        setTimeout(() => {
          this.$Message.error({
            content: `定位点超过${max},系统已将超出的点隐藏。请缩小时间范围再试！`,
            duration: 3,
          });
        }, 50);
      }
      //console.log(trackList);
      return trackList.map((track, i) => {
        //console.log(track,this.iconMap[track.locateType]);
        //let trackAddressNode = getAddressNode(track);
        return this.createMarker(track, i);
      });
    },
    exportExcel() {
      let queryData = this.getTrailQueryData();
      if (!queryData) return;
      const { href } = this.$router.resolve({
        path: "/xhk_web/export",
        query: {
          service: "device/export_track",
          fileName: `${queryData.device}-${encodeURIComponent("轨迹")}`,
          ...queryData,
        },
      });
      window.open(href, "_blank");
    },
    hideTable() {
      this.showDetailTable = false;
    },
    findTrackPointByLocateTime(locateTime) {
      if (_cacheTableData) {
        return _.find(_cacheTableData, (track) => {
          return track.locateTime === locateTime;
        });
      }
    },
    getPageType() {
      this.page =
        this.$route.path.indexOf("transport_bill_track") > -1
          ? "billTrack"
          : this.$route.path.indexOf("trouble_shoot") > -1
          ? "troubleShoot"
          : "";
    },
    setFenceCircle() {
      let { map } = this;

      this.fenceInfoList.forEach((info) => {
        const circle = new AMap.Circle({
          center: new AMap.LngLat(info.longitude, info.latitude), // 圆心位置
          radius: info.triggerScope, // 圆半径
          fillColor: info.type === 1 ? "#0076ff" : "#FACD91", // 圆形填充颜色
          fillOpacity: 0.08,
          strokeColor: info.type === 1 ? "#0076ff" : "#FACD91", // 描边颜色
          strokeWeight: 1, // 描边宽度
          strokeStyle: "dashed",
          zIndex: 10,
        });
        const icon = new AMap.Icon({
          size: new AMap.Size(40, 40),
          image:
            "https://wlhyos-resource.oss-cn-hangzhou.aliyuncs.com/wlhyos/public/media/yingjianguanlixitong/zhongxindian.svg",
          imageOffset: new AMap.Pixel(0, 0),
          imageSize: new AMap.Size(40, 40),
        });
        const marker = new AMap.Marker({
          position: [info.longitude, info.latitude],
          icon: icon,
          offset: new AMap.Pixel(-20, -30),
        });
        map.add(marker);
        map.add(circle);
      });
    },
    setDeviceAndSearch(data) {
      this.device = data.device;

      let timeArr = [];
      data.fenceInfoList.forEach((item) => {
        if (item.type === 1 && item.triggerStatus === 1) {
          timeArr[0] = item.triggerTimeStr;
        } else if (item.type === 3 && item.triggerStatus === 1) {
          timeArr[1] = item.triggerTimeStr;
        }
      });
      if (!timeArr[1]) {
        timeArr[1] = new Date();
      }
      this.timeRange = timeArr;
      this.fenceInfoList = data.fenceInfoList;
      this.getTrailByDevice();
    },
    drawTrail() {
      this.trackList = !this.positionType.length ? this.totalTrackList : [];
      this.positionType.forEach((item) => {
        const filterList = this.totalTrackList.filter(
          (v) => v.locateType === item
        );
        this.trackList = this.trackList.concat(filterList);
      });

      _cacheTableData = [].concat(this.trackList);
      _cacheTableData.reverse(); //倒序
      this.totalItems = _cacheTableData.length;
      //轨迹
      this.setPathData(this.trackList);

      if (this.showDetail) {
        setTimeout(() => {
          this.setMapMarker();
        }, 300);
        this.refreshTable();
      }
    },
    positionTypeChange(e) {
      this.map && this.map.clearMap();
      this.pathSimplifierIns && this.pathSimplifierIns.setData();
      this.drawTrail();
      this.setFenceCircle();
    },
    selectRadio(key) {
      this.speedLevel = key;
    },
  },
  mounted() {
    console.log(this.$route.path);
    var winHeight = window.innerHeight;
    document.getElementById("trail").style.height =
      winHeight - (this.$route.path === "/devices/trail_back" ? 48 : 0) + "px";
    if (this.device) {
      this.getTrailByDevice();
    }
    let vm = this;
    window.getDeviceLocation = function(deviceNumber, locateTime) {
      //console.log(deviceNumber,locateTime);
      let pointData = vm.findTrackPointByLocateTime(locateTime);
      let { map } = vm;
      //console.log(pointData);
      getLocation(pointData, (err, point) => {
        let addressNode = document.querySelector(`#addr-${locateTime}`);
        addressNode.innerHTML = `<span>定位地点：</span>${point.address}`;
        // let infoWindow = point._infoWindow;
        // let pos = infoWindow.getPosition();
        // infoWindow.open(map, pos);
        //console.log(pos);
        //console.log('point:',point,addressNode)
      });
    };

    this.getPageType();
  },
};
</script>
