var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "marker-cont" },
    _vm._l(_vm.info, function(item, idx) {
      return _c(
        "div",
        {
          key: idx,
          staticStyle: {
            display: "flex",
            "flex-direction": "column",
            "align-items": "center"
          },
          style: idx !== 0 ? "margin-top:12px;" : ""
        },
        [
          _c("img", {
            staticStyle: {
              width: "12px",
              height: "15px",
              "margin-right": "4px"
            },
            attrs: { src: item.icon }
          }),
          _vm._v(_vm._s(item.label) + " ")
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }