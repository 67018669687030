var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "page-content trail",
      style:
        "height: " +
        (_vm.page === "billTrack"
          ? "calc(100vh - 48px)"
          : _vm.page === "troubleShoot"
          ? "calc(100vh - 48px)"
          : "100%") +
        " ;",
      attrs: { id: "trail" }
    },
    [
      _c(
        "Spin",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          attrs: { size: "large", fix: "" }
        },
        [_vm._v("加载中...")]
      ),
      _vm.page !== "billTrack"
        ? _c(
            "div",
            {
              staticClass: "absolute-top",
              style:
                "" +
                (_vm.page === "troubleShoot"
                  ? "width: 1150px; left: 40%"
                  : "width: 900px; left: 50%")
            },
            [
              _vm.page === "troubleShoot"
                ? _c(
                    "div",
                    { staticClass: "input-group-inline" },
                    [
                      _vm._v(" 运单号 "),
                      _c("Input", {
                        staticClass: "control-width",
                        attrs: { placeholder: "请输入运单号" },
                        model: {
                          value: _vm.shippingNoteNumber,
                          callback: function($$v) {
                            _vm.shippingNoteNumber = $$v
                          },
                          expression: "shippingNoteNumber"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "div",
                { staticClass: "input-group-inline" },
                [
                  _vm._v(" 设备号 "),
                  _c("Input", {
                    staticClass: "control-width",
                    attrs: { placeholder: "请输入设备号" },
                    model: {
                      value: _vm.device,
                      callback: function($$v) {
                        _vm.device = $$v
                      },
                      expression: "device"
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "input-group-inline" },
                [
                  _vm._v(" 时间 "),
                  _c("DatePicker", {
                    staticStyle: { width: "360px" },
                    attrs: {
                      type: "datetimerange",
                      "split-panels": "",
                      placeholder: "设备运行时间",
                      value: _vm.timeRange,
                      format: "yyyy-MM-dd HH:mm:ss",
                      editable: false
                    },
                    on: { "on-change": _vm.updateTimeRange }
                  })
                ],
                1
              ),
              !_vm.loading
                ? _c(
                    "div",
                    { staticClass: "input-group-inline" },
                    [
                      _c(
                        "Button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.getTrailByDevice }
                        },
                        [_vm._v("确定")]
                      ),
                      _c(
                        "Button",
                        {
                          staticStyle: { color: "#2d8cf0" },
                          attrs: {
                            type: "text",
                            size: "default",
                            loading: _vm.detailLoading
                          },
                          on: { click: _vm.toggleTrailDetail }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.showDetailTable ? "关闭明细" : "轨迹明细"
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        : _vm._e(),
      _c("div", { staticClass: "map-box", attrs: { id: "container" } }),
      _c("div", { staticClass: "absolute-bottom" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showDetailTable,
                expression: "showDetailTable"
              }
            ]
          },
          [
            _c("Table", {
              ref: "table",
              attrs: {
                id: "table",
                "highlight-row": "",
                stripe: "",
                border: "",
                size: "small",
                height: 200,
                columns: _vm.columns,
                loading: _vm.loading,
                data: _vm.tableData
              }
            }),
            _c(
              "div",
              { staticStyle: { padding: "5px", overflow: "hidden" } },
              [
                _c(
                  "Button",
                  {
                    staticStyle: { "margin-right": "5px" },
                    attrs: { size: "small" },
                    on: { click: _vm.hideTable }
                  },
                  [_vm._v("关闭列表")]
                ),
                _c(
                  "Button",
                  { attrs: { size: "small" }, on: { click: _vm.exportExcel } },
                  [_vm._v("导出列表")]
                ),
                _c(
                  "div",
                  { staticStyle: { float: "right" } },
                  [
                    _c("Page", {
                      attrs: {
                        total: _vm.totalItems,
                        current: _vm.currentPage,
                        "show-total": "",
                        "page-size": _vm.pageSize
                      },
                      on: { "on-change": _vm.changePage }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          staticClass: "map-navigator-control flex flex-middle",
          style: _vm.page === "billTrack" ? "top: 5px;" : "top: 65px;"
        },
        [
          _c(
            "div",
            { staticClass: "flex" },
            _vm._l(_vm.speedRadioList, function(item, idx) {
              return _c(
                "div",
                {
                  key: idx,
                  staticClass: "speed-radio",
                  class:
                    _vm.speedLevel === item
                      ? "select-radio"
                      : "not-select-radio",
                  on: {
                    click: function($event) {
                      return _vm.selectRadio(item)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(item) + " ")]
              )
            }),
            0
          ),
          _c(
            "div",
            {
              staticStyle: {
                width: "320px",
                margin: "0 10px",
                position: "relative",
                "z-index": "1001"
              }
            },
            [
              _c("Slider", {
                attrs: { "tip-format": _vm.navigatorProgressTip },
                on: { "on-change": _vm.changeNavigatorProgress },
                model: {
                  value: _vm.navigatorProgress,
                  callback: function($$v) {
                    _vm.navigatorProgress = $$v
                  },
                  expression: "navigatorProgress"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-right": "10px" } },
            [
              _c(
                "Button",
                {
                  staticStyle: {
                    border: "none",
                    color: "white",
                    background: "none",
                    "font-size": "10px"
                  },
                  attrs: { size: "small", type: "text", disabled: "" }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(Math.round(_vm.playDistance / 1000)) +
                      "km/" +
                      _vm._s(Math.round(_vm.totalDistance / 1000)) +
                      "km "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "speed-box" },
            [
              _c("Icon", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.navigatorState === "stop" ||
                      _vm.navigatorState === "pause",
                    expression:
                      "navigatorState === 'stop' || navigatorState === 'pause'"
                  }
                ],
                attrs: { type: "md-play", color: "#fff", size: "16" },
                on: { click: _vm.playNavigator }
              }),
              _c("Icon", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.navigatorState === "play",
                    expression: "navigatorState === 'play'"
                  }
                ],
                attrs: { type: "md-pause", color: "#fff", size: "16" },
                on: { click: _vm.stopNavigator }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "margin-left": "16px", color: "white" } },
            [
              _vm._v(" 定位方式： "),
              _c(
                "Select",
                {
                  staticStyle: { width: "200px" },
                  attrs: { multiple: "", size: "small" },
                  on: { "on-change": _vm.positionTypeChange },
                  model: {
                    value: _vm.positionType,
                    callback: function($$v) {
                      _vm.positionType = $$v
                    },
                    expression: "positionType"
                  }
                },
                _vm._l(_vm.positionList, function(item) {
                  return _c(
                    "Option",
                    { key: item.value, attrs: { value: item.value } },
                    [_vm._v(_vm._s(item.label))]
                  )
                }),
                1
              )
            ],
            1
          )
        ]
      ),
      _c("marker-tip", { staticClass: "marker-tip" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }