<template>
  <div class="marker-cont">
    <div
      v-for="(item, idx) in info"
      :key="idx"
      style="display:flex;flex-direction:column;align-items:center;"
      :style="idx !== 0 ? 'margin-top:12px;' : ''"
    >
      <img
        :src="item.icon"
        style="width: 12px; height: 15px;margin-right: 4px;"
      />{{ item.label }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'markerTip',
  data() {
    return {
      info: [
        { icon: 'https://oss.kuaihuoyun.com/xhk-bops/base.png', label: '基站' },
        { icon: 'https://oss.kuaihuoyun.com/xhk-bops/gps.png', label: 'GPS' },
        { icon: 'https://oss.kuaihuoyun.com/xhk-bops/wifi.png', label: 'WIFI' },
      ],
    }
  },
}
</script>

<style lang="less">
.marker-cont {
  background-color: white;
  padding: 8px;
  border-radius: 8px;
  font-size: 10px;
  color: #333;
}
</style>
